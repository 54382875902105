'use client'

import { useRef } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useFormState } from 'react-dom'
import { useForm } from 'react-hook-form'
import type { z } from 'zod'

import { cn } from '@/lib/utils'
import { signInSchema } from '@/lib/validations/auth'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import Message from '@/components/message'
import { PasswordInput } from '@/components/password-input'
import SubmitButton from '@/components/submit-button'

import { loginAction } from '../actions'
import { LOGIN_VIEW } from '../templates/login-template'
import LastUsedMethod from './last-used-method'

type Inputs = z.infer<typeof signInSchema>

type SignInFormProps = {
  setCurrentView: (view: LOGIN_VIEW) => void
  lastSignedInMethod: string | undefined
}

export default function SignInForm({
  setCurrentView,
  lastSignedInMethod,
}: SignInFormProps) {
  const [state, formAction] = useFormState(loginAction, {
    description: '',
    title: '',
  })

  const form = useForm<Inputs>({
    resolver: zodResolver(signInSchema),
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const formRef = useRef<HTMLFormElement>(null)

  return (
    <Form {...form}>
      <form ref={formRef} action={formAction} className='grid gap-4'>
        <FormField
          control={form.control}
          name='email'
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input
                  id='email'
                  type='email'
                  placeholder='youremail@example.com'
                  required
                  {...field}
                />
              </FormControl>
              <FormMessage className='max-w-sm' />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name='password'
          render={({ field }) => (
            <FormItem>
              <div className='flex items-center'>
                <FormLabel>Password</FormLabel>
                <button
                  onClick={() => setCurrentView(LOGIN_VIEW.FORGOT)}
                  className='ml-auto inline-block text-sm text-muted-foreground underline transition-colors hover:text-foreground'
                  type='button'
                >
                  Forgot your password?
                </button>
              </div>
              <FormControl>
                <PasswordInput placeholder='**********' {...field} />
              </FormControl>
              <FormMessage className='max-w-sm' />
            </FormItem>
          )}
        />
        <SubmitButton
          className={cn(
            'relative my-2 w-full',
            lastSignedInMethod === 'email' && 'ring-primary ring-1 ring-offset-1'
          )}
        >
          Login
          {lastSignedInMethod === 'email' && <LastUsedMethod className='bg-card text-foreground' />}
        </SubmitButton>
      </form>
      <div className='my-8 text-center text-sm text-muted-foreground'>
        <span className='mr-1 hidden sm:inline-block'>
          Don&apos;t have an account?
        </span>
        <button
          onClick={() => setCurrentView(LOGIN_VIEW.REGISTER)}
          aria-label='Sign up'
          className='font-medium text-primary hover:underline'
        >
          Sign up
        </button>
        <span> for free.</span>
      </div>
      <Message message={state} />
    </Form>
  )
}
