'use client'

import Image from 'next/image'
import Link from 'next/link'

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'
import OAuthSignIn from '../components/oauth-signin'
import SignInForm from '@/components/account/components/signin-form'
import { useState } from 'react'
import RegisterForm from '../components/register-form'
import ForgotPasswordForm from '../components/forgot-password'

export enum LOGIN_VIEW {
  SING_IN = 'sign-in',
  REGISTER = 'register',
  FORGOT = 'forgot'
}

export default function LoginTemplate({ lastSignedInMethod }: { lastSignedInMethod: string | undefined }) {
  const [currentView, setCurrentView] = useState('sign-in')

  return (
    <Card className='mx-auto my-2 w-full max-w-md'>
      <CardHeader className='items-center'>
        <Link href='/' className='my-4'>
          <Image
            src='/logo.png'
            priority
            alt='Logo'
            className='h-12 w-auto dark:hidden'
            width={709}
            height={281}
          />
          <Image
            src='/logo_dark.png'
            alt='Logo dark'
            priority
            className='hidden h-12 w-auto dark:inline-block'
            width={709}
            height={281}
          />
        </Link>
        <CardTitle className='text-xl'>
          {currentView === 'sign-in' && 'Sign In'}
          {currentView === 'register' && 'Create New Account'}
          {currentView === 'forgot' && 'Forgot password'}
        </CardTitle>
        <CardDescription>
          {currentView === 'sign-in' && 'Use your email and password to sign in.'}
          {currentView === 'register' && 'Use email and password to create new account.'}
          {currentView === 'forgot' && 'Enter email to recieve password reset link.'}
        </CardDescription>
      </CardHeader>
      <CardContent className='space-y-4'>
        {currentView === 'sign-in' && <SignInForm setCurrentView={setCurrentView} lastSignedInMethod={lastSignedInMethod} />}
        {currentView === 'register' && <RegisterForm setCurrentView={setCurrentView} />}
        {currentView === 'forgot' && <ForgotPasswordForm setCurrentView={setCurrentView} />}
        <div className='relative py-2'>
          <div className='absolute inset-0 flex items-center'>
            <span className='w-full border-t' />
          </div>
          <div className='relative flex justify-center text-xs uppercase'>
            <span className='bg-card px-2 text-muted-foreground'>
              Or continue with
            </span>
          </div>
        </div>
        <OAuthSignIn lastSignedInMethod={lastSignedInMethod} />
      </CardContent>
    </Card>
  )
}
