'use client'

import { useRef } from 'react'

import { Input } from '@/components/ui/input'

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"

import SubmitButton from '@/components/submit-button'
import { LOGIN_VIEW } from '../templates/login-template'
import { requestPasswordUpdate } from '../actions'
import { z } from 'zod'
import { forgotPasswordSchema } from '@/lib/validations/auth'
import { useFormState } from 'react-dom'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import Message from '@/components/message'

type Inputs = z.infer<typeof forgotPasswordSchema>

interface ForgotPasswordFormProps {
  setCurrentView: (view: LOGIN_VIEW) => void
}

export default function ForgotPasswordForm({
  setCurrentView,
}: ForgotPasswordFormProps) {
  const [message, formAction] = useFormState(requestPasswordUpdate, {
    description: '',
    title: ''
  })

  const form = useForm<Inputs>({
    resolver: zodResolver(forgotPasswordSchema),
    mode: 'onChange',
    defaultValues: {
      email: ''
    }
  })

  const formRef = useRef<HTMLFormElement>(null)

  return (
    <Form {...form}>
      <form
        ref={formRef}
        action={formAction}
        className='grid gap-4'
      >
        <FormField control={form.control} name='email' render={({ field }) => (
          <FormItem>
            <FormLabel>Email</FormLabel>
            <FormControl>
              <Input type='email' id='email' placeholder='youremail@example.com' autoCapitalize='none' autoComplete='email' autoCorrect='off' required  {...field} />
            </FormControl>
            <FormMessage className='max-w-sm' />
          </FormItem>
        )} />
        <SubmitButton className="my-2 w-full">
          Send Email
        </SubmitButton>
      </form>
      <div className='my-6 text-center text-sm text-muted-foreground'>
        <span className='mr-1 hidden sm:inline-block'>
          Don&apos;t have an account?
        </span>
        <button
          onClick={() => setCurrentView(LOGIN_VIEW.REGISTER)}
          aria-label='Sign up'
          className='font-medium text-primary hover:underline'
        >
          Sign up
        </button>
        <span> for free.</span>
      </div>
      <Message message={message} />
    </Form>
  )
}
