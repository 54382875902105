import { cn } from '@/lib/utils'

interface LastUsedMethodProps extends React.HTMLAttributes<HTMLSpanElement> { }

export default function LastUsedMethod({ className }: LastUsedMethodProps) {
  return (
    <div
      className={cn(
        'absolute right-4 z-40 rounded-md px-1.5 text-xs',
        className
      )}
    >
      <span className='absolute left-[-4px] top-1/2 -z-10 size-3 -translate-y-1/2 rotate-45 rounded-sm bg-inherit' />
      Last used
    </div>
  )
}
