'use client'

import { useEffect } from "react"
import { toast } from "sonner"

interface MessageProps {
  message: {
    title?: string
    description: string
  }
}

export default function Message({ message }: MessageProps) {
  useEffect(() => {
    if (message.description) {
      if (message.title === 'Failed!') {
        toast.error(message.description)
      } else {
        toast.success(message.title, {
          description: message.description
        })
      }
    }
  }, [message.description, message.title])

  return null
}
