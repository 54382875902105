'use client'

import { useRef } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import type { z } from 'zod'

import { registrationSchema } from '@/lib/validations/auth'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'

import { PasswordInput } from '@/components/password-input'
import { LOGIN_VIEW } from '../templates/login-template'
import { useFormState } from 'react-dom'
import { registerAction } from '../actions'
import SubmitButton from '@/components/submit-button'
import Message from '@/components/message'

type Inputs = z.infer<typeof registrationSchema>

type SignInFormProps = {
  setCurrentView: (view: LOGIN_VIEW) => void
}

export default function RegisterForm({ setCurrentView }: SignInFormProps) {
  const [state, formAction] = useFormState(registerAction, {
    description: "",
    title: ''
  })

  const form = useForm<Inputs>({
    resolver: zodResolver(registrationSchema),
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
      confirmPassword: '',
    },
  })

  const formRef = useRef<HTMLFormElement>(null)

  return (
    <Form {...form}>
      <form
        ref={formRef}
        className='grid gap-4'
        action={formAction}
      >
        <FormField
          control={form.control}
          name='email'
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input placeholder='youremail@example.com' type='email' id='email' {...field} />
              </FormControl>
              <FormMessage className='max-w-sm' />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name='password'
          render={({ field }) => (
            <FormItem>
              <FormLabel>Password</FormLabel>
              <FormControl>
                <PasswordInput placeholder='**********' {...field} />
              </FormControl>
              <FormMessage className='max-w-sm' />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name='confirmPassword'
          render={({ field }) => (
            <FormItem>
              <FormLabel>Confirm Password</FormLabel>
              <FormControl>
                <PasswordInput placeholder='**********' {...field} />
              </FormControl>
              <FormMessage className='max-w-sm' />
            </FormItem>
          )}
        />
        <SubmitButton className="my-2 w-full">
          Sign up
          <span className='sr-only'>
            Sign up
          </span>
        </SubmitButton>
      </form>
      <div className='my-8 text-center text-sm text-muted-foreground'>
        <span className='mr-1 hidden sm:inline-block'>
          Already have an account? Go to
        </span>
        <button
          onClick={() => setCurrentView(LOGIN_VIEW.SING_IN)}
          aria-label='Sign in'
          type='button'
          className='font-medium text-primary hover:underline'
        >
          Sign in
        </button>
        <span>.</span>
      </div>
      <Message message={state} />
    </Form>
  )
}
