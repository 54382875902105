'use client'

import { Icons } from '@/components/icons'
import { type Provider } from '@supabase/supabase-js'
import { loginWithOauth } from '../actions'
import SubmitButton from '@/components/submit-button'
import LastUsedMethod from './last-used-method'
import { cn } from '@/lib/utils'

export type OAuthProviders = {
  name: Provider
  displayName: string
  icon: JSX.Element
}

const oauthProviders: OAuthProviders[] = [
  { name: 'google', icon: <Icons.google className='size-4' />, displayName: 'Google' },
  { name: 'github', icon: <Icons.github className='size-4' />, displayName: 'Github' },
  { name: 'discord', icon: <Icons.discord className='size-4' />, displayName: 'Discord' },
]

export default function OAuthSignIn({ lastSignedInMethod }: { lastSignedInMethod: string | undefined }) {
  return (
    <div className='grid gap-2'>
      {oauthProviders.map((provider) => (
        <form key={provider.name} action={loginWithOauth}>
          <input type='hidden' name='provider' value={provider.name} />
          <SubmitButton
            aria-label={`Sign in with ${provider.name}`}
            variant='outline'
            className={cn(
              'relative w-full',
              lastSignedInMethod === provider.name && 'ring-primary ring-1 ring-offset-1'
            )}
          >
            <span className='mr-2'>{provider.icon}</span>
            <span>{provider.displayName}</span>
            {lastSignedInMethod === provider.name && <LastUsedMethod className='bg-primary text-muted' />}
          </SubmitButton>
        </form>
      )
      )}
    </div>
  )
}
