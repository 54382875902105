import { clsx, type ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function isMacOs() {
  if (typeof window === 'undefined') return false

  return window.navigator.userAgent.includes('Mac')
}

export function formatDate(
  date: Date | string | number,
  opts: Intl.DateTimeFormatOptions = {}
): string {
  return new Intl.DateTimeFormat('en-US', {
    month: opts.month ?? 'long',
    day: opts.day ?? 'numeric',
    year: opts.year ?? 'numeric',
  }).format(new Date(date))
}

export function timeAgo(date: Date) {
  const rtf = new Intl.RelativeTimeFormat('en', {
    numeric: 'auto',
    style: 'short',
  })
  const now = new Date()
  const elapsed = (date.getTime() - now.getTime()) / 1000
  const intervals: { [unit: string]: number } = {
    year: 31536000,
    month: 2592000,
    week: 604800,
    day: 86400,
    hour: 3600,
    minute: 60,
    second: 1,
  }

  for (const [unit, secondsInUnit] of Object.entries(intervals)) {
    if (Math.abs(elapsed) >= secondsInUnit || unit === 'second') {
      const value = Math.round(elapsed / secondsInUnit)
      return rtf.format(value, unit as Intl.RelativeTimeFormatUnit)
    }
  }

  return ''
}

export function formatBytes(
  bytes: number,
  opts: {
    decimals?: number
    sizeType?: 'accurate' | 'normal'
  } = {}
) {
  const { decimals = 0, sizeType = 'normal' } = opts

  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  const accurateSizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB']
  if (bytes === 0) return '0 Byte'
  const i = Math.floor(Math.log(bytes) / Math.log(1024))
  return `${(bytes / Math.pow(1024, i)).toFixed(decimals)} ${
    sizeType === 'accurate'
      ? (accurateSizes[i] ?? 'Bytest')
      : (sizes[i] ?? 'Bytes')
  }`
}

export function isValidEmail(email: string) {
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
  return regex.test(email)
}

export function isBoolean(value: any): value is boolean {
  return typeof value === 'boolean'
}

export function keepKeysWithPrefix(
  obj: Record<string, unknown>,
  prefix: string
): Record<string, unknown> {
  const newObj: Record<string, unknown> = {}

  for (const key in obj) {
    if (key.startsWith(prefix)) {
      newObj[key] = obj[key]
    }
  }

  return newObj
}

function hasMatchingValues(obj: ObjectType, xValue: number, yValue: number) {
  let matchCount = 0

  for (const key in obj) {
    if (
      obj.hasOwnProperty(key) &&
      (obj[key] === xValue || obj[key] === yValue)
      && key !== 'pareto'
    ) {
      matchCount++
    }
  }

  return matchCount === 2
}

export function getClickedPoint(
  x: number,
  y: number,
  dataOnPlot: ObjectType[]
) {
  const allPoints: HTMLElement[] = Array.from(
    document.querySelectorAll('.custom-dot')
  )
  for (let i = 0; i < allPoints.length; i++) {
    const { chartX, chartY, xValue, yValue, radius } = allPoints[i].dataset

    // calculate distance between 2 points
    const pointX = Number(chartX)
    const pointY = Number(chartY)
    const deltaX = x - pointX
    const deltaY = y - pointY
    const distance = Math.sqrt(Math.pow(deltaX, 2) + Math.pow(deltaY, 2))

    if (radius) {
      if (distance <= Number(radius) * 2) {
        const dataPoint = dataOnPlot.find((data) =>
          hasMatchingValues(data, Number(xValue), Number(yValue))
        )
        if (dataPoint) {
          return dataPoint
        }
      }
    }
  }
}

export function generateRandomValue(
  lower: number,
  upper: number,
  type: 'continuous' | 'integer' | 'binary' | 'categorical'
): number {
  switch (type) {
    case 'continuous':
      return parseFloat(
        (Math.random() * (upper - lower) + lower).toPrecision(10)
      )
    case 'integer':
      return Math.floor(Math.random() * (upper - lower + 1)) + lower
    case 'binary':
      return Math.round(Math.random())
    default:
      return 0
  }
}

export function checkIfEvaluatedObjectives(arr: any[]): boolean {
  for (const obj of arr) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && !obj[key]) {
        return false // If any key in any object has a falsy value, return false
      }
    }
  }
  return true
}

export function transformObject(inputObject: ObjectType) {
  return Object.entries(inputObject!).map(([key, value]) => ({
    label: key,
    value: isNaN(value) ? String(value) : Number(value),
  }))
}

export function hasElementGreaterThanZero(array: number[]): boolean {
  return array.some((value) => value > 0)
}

export function convertArrayToHypervolumeData(numbersArray: number[]) {
  return numbersArray.map((value, index) => ({
    samples: index,
    hypervolumeData: Number(value),
  }))
}

type ResultObjectType = {
  sample: number
  [key: string]: number | string
}

export function convertArrayToArraysOfObjects(
  originalArray: ObjectType[]
): ResultObjectType[][] {
  const keys = Object.keys(originalArray[0]) // Assuming the array is not empty
  const resultArrays = keys.map((key) => {
    return originalArray.map((obj, index) => ({
      sample: index,
      [key]: Number(obj[key]),
    }))
  })

  return resultArrays
}

export function removeAndInsert(
  array: ObjectType[],
  key: string,
  valueToRemove: number,
  objectsToInsert: ObjectType[]
) {
  const filteredArray = array.filter((item) => item[key] !== valueToRemove)

  const indexToRemove = array.findIndex((item) => item[key] === valueToRemove)

  filteredArray.splice(indexToRemove, 0, ...objectsToInsert)

  return filteredArray
}

export function convertValuesToNumbers(array: ObjectType[]) {
  const numericArray = array?.map((obj) => {
    const newObj: ObjectType = {}
    for (const key in obj) {
      newObj[key] = Number(obj[key])
    }
    return newObj
  })
  return numericArray
}

export function convertObjectValuesToNumbers(obj: ObjectType) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      obj[key] = Number(obj[key])
    }
  }
  return obj
}

export function objectsHaveSameValues(
  obj1: { [key: string]: any },
  obj2: { [key: string]: any }
): boolean {
  for (const key in obj1) {
    if (obj1.hasOwnProperty(key) && key !== 'pareto') {
      if (obj2[key] !== obj1[key]) {
        return false
      }
    }
  }
  return true
}

export function findIndexByObject(
  array: ObjectType[],
  searchObject: ObjectType
): number {
  for (let i = 0; i < array.length; i++) {
    const currentObject = array[i]

    if (
      Object.keys(currentObject).every(
        (key) =>
          searchObject.hasOwnProperty(key) &&
          currentObject[key] === searchObject[key]
      )
    ) {
      return i // Return the index if the objects match
    }
  }

  return 0 // Return -1 if the object is not found in the array
}

export function filterAndRemoveObjectKeys(
  allowedKeys: string[],
  objects: { [key: string]: any }[]
) {
  return objects.map((obj) => {
    const filteredObj: { [key: string]: any } = {}
    allowedKeys.forEach((key) => {
      if (obj.hasOwnProperty(key)) {
        filteredObj[key] = obj[key]
      }
    })
    return filteredObj
  })
}

export function filterAndAddObjectKeys(
  allowedKeys: string[],
  objects: { [key: string]: any }[]
) {
  return objects.map((obj) => {
    const filteredObj: { [key: string]: any } = {}
    allowedKeys.forEach((key) => {
      if (obj.hasOwnProperty(key)) {
        filteredObj[key] = obj[key]
      } else {
        filteredObj[key] = ''
      }
    })
    return filteredObj
  })
}

export function reorderObjectKeys(
  obj: { [key: string]: string },
  order: string[]
) {
  const orderedObj: { [key: string]: string } = {}
  order.forEach((key) => {
    if (obj.hasOwnProperty(key)) {
      orderedObj[key] = obj[key]
    }
  })
  return orderedObj
}

type Item = {
  [key: string]: any
}

export function findMinMax<T extends Item>(
  items: T[],
  key: keyof T
): { min: T[keyof T]; max: T[keyof T] } {
  return items.reduce(
    (acc, item) => {
      if (item[key] < acc.min) acc.min = item[key]
      if (item[key] > acc.max) acc.max = item[key]
      return acc
    },
    { min: items[0][key], max: items[0][key] }
  )
}

export function findMissingElementsIndexes<T extends ObjectType>(
  originalArray: T[],
  partialArray: T[]
): number[] {
  const missingIndexes: number[] = []

  const elementMap = new Map<string, number>()

  partialArray.forEach((element) => {
    const elementKey = JSON.stringify(element)
    elementMap.set(elementKey, (elementMap.get(elementKey) || 0) + 1)
  })

  originalArray.forEach((element, index) => {
    const elementKey = JSON.stringify(element)
    if (elementMap.has(elementKey) && elementMap.get(elementKey)! > 0) {
      elementMap.set(elementKey, elementMap.get(elementKey)! - 1)
    } else {
      missingIndexes.push(index)
    }
  })

  return missingIndexes
}

export function removeElementsAtIndexes<T>(array: T[], indexes: number[]): T[] {
  const filteredArray = array.filter((_, index) => !indexes.includes(index))

  return filteredArray
}

export function findMinExceptZero(arr: number[]): number {
  const filteredArr = arr.filter((num) => num !== 0)

  return Math.min(...filteredArr)
}
