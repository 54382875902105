'use client'

import { Button, ButtonProps } from "./ui/button"
import React from "react"
import { useFormStatus } from "react-dom"


export default function SubmitButton({ children, ...props }: ButtonProps) {
  const { pending } = useFormStatus()

  return (
    <Button type="submit" isLoading={pending} disabled={pending} {...props}>
      {children}
    </Button>
  )
}
